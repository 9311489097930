<template>
  <div>
    <v-btn-toggle
      mandatory
      fixed
      app
      dense
      group
      name="RoutingToolbar"
      color="white"
    >
      <v-tooltip :disabled="isShowTooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn dark name="Car" v-on="on" @click="enterModeInRouting('car')">
            <v-icon>mdi-car</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Car") }}</span>
      </v-tooltip>
      <v-tooltip :disabled="isShowTooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn dark name="Bus" v-on="on" @click="enterModeInRouting('bus')">
            <v-icon>mdi-bus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Public") }}</span>
      </v-tooltip>
      <v-tooltip :disabled="isShowTooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            v-on="on"
            name="Truck"
            @click="enterModeInRouting('truck')"
          >
            <v-icon>mdi-truck</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Truck") }}</span>
      </v-tooltip>
      <v-tooltip :disabled="isShowTooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn dark v-on="on" name="Walk" @click="enterModeInRouting('walk')">
            <v-icon>mdi-walk</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Pedestrian") }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <v-tooltip :disabled="isShowTooltip" top>
      <template v-slot:activator="{ on }">
        <v-btn dark v-on="on" color="primary" @click="openDirectionsDialog()">
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Route Directions") }}</span>
    </v-tooltip>
    <v-tooltip :disabled="isShowTooltip" top>
      <template v-slot:activator="{ on }">
        <v-btn dark v-on="on" color="primary" @click="setRouteOverview()">
          <v-icon>mdi-application</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Route overview") }}</span>
    </v-tooltip>
    <span class="routeSummaryStyle" text>
      {{ $t("Distance") + ":" }} {{ distanceValue }}
    </span>
    <span class="routeSummaryStyle" text>
      {{ $t("Time") + ":" }} {{ timeValue }}
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import HereUtil from "../util/here";

export default {
  name: "routing",
  computed: {
    distanceValue() {
      return this.$store.getters["routing/distance"];
    },
    timeValue() {
      return this.$store.getters["routing/time"];
    },
    ...mapState({
      currentFeatureInfo: state => state.featurePanel.currentFeatureInfo,
      currentTransportMode: state => state.routing.currentTransportMode,
      isShowTooltip: state => !state.setting.isToolTipsOn
    })
  },
  created() {
    console.log("...Routing panel created.");
  },
  methods: {
    enterModeInRouting(mode) {
      this.$insProgress.start();
      if (mode === "walk") {
        mode = "pedestrian";
      }
      this.$store.dispatch("routing/handleChangeTransportMode", mode);
    },
    openDirectionsDialog() {
      this.$store.commit("routing/changeIsRouteDirectionWindowOpen", true);
    },
    setRouteOverview() {
      let hereUtil = new HereUtil();
      hereUtil.setRouteOverview();
    }
  }
};
</script>
<style scoped>
.routeSummaryStyle {
  padding: 10px;
  color: white;
}
#directionsPanel {
  right: 30px;
  top: 25px;
  width: 350px;
  position: absolute;
  z-index: 100;
}
</style>
